import "./articlesPageStyle.scss";
import ListItem from "../../list/listItem";
import data from "../../data/articles.json";
import Button from "../../button/button";
import { useState } from "react";
const category = ["all", "slots", "aviator", "blackjack", "betting", "poker"];
export default function ArticlesPage() {
  const [selectedCategories, setSelected] = useState(["all"]);

  return (
    <div className="article_page_container container">
      <ul className="list">
        {data.map((item) => (
          <ListItem params={item} />
        ))}
      </ul>
    </div>
  );
}
