import Button from "../button/button";
import { useNavigate } from "react-router-dom";
import "./style.scss";
export default function Hero() {
  const navigation = useNavigate();
  const handleBtnClick = () => {
    navigation("/slots");
  };

  return (
    <div
      className="baner_container custom_font"
      style={{ backgroundImage: "url(/background-img.png)" }}
    >
      <div
        className="hero_baner"
        style={{ backgroundImage: "url(/article_images/baner-img.png)" }}
      >
        <h1 className="hero_title">
          Jackpot Wisdom: Spin the Reels of Knowledge
        </h1>
        <div className="text_container">
          <p className="text">
            Dive into the world's finest gambling lore. From strategies to
            stories, elevate your game with every article.
          </p>
        </div>
      </div>
    </div>
  );
}
