import { NavLink } from "react-router-dom";
import "./listStyleBase.scss";

export default function ListItem({ params }: any) {
  const truncateTitle = (title: string) => {
    const maxLength = 40;
    return title.length > maxLength
      ? title.substring(0, maxLength) + "..."
      : title;
  };
  return (
    <li
      className="list_item"
      key={params.id}
      style={{ backgroundImage: `url(${params.img})` }}
    >
      <NavLink to={`/articles/article/${params.id}`} className="article_link">
        <div className="text_container ">
          <h3 className="title custom_font">{truncateTitle(params.title)}</h3>
        </div>
      </NavLink>
    </li>
  );
}
