import "./homePageStyle.scss";
import data from "../../data/articles.json";
import ListItem from "../../list/listItem";
import Hero from "../../baners/hero";
import { useEffect, useState } from "react";
export default function HomePage() {
  const [show, setShow] = useState(true);

  return (
    <>
      <Hero />
      <div
        className=" home_page_container container "
        style={{ backgroundImage: "url(/background-img.png)" }}
      >
        <ul className="home_list custom_font">
          {data.map((item) => (
            <ListItem params={item} key={item.id} />
          ))}
        </ul>
      </div>
    </>
  );
}
