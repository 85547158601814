import React, { useState } from "react";
import HomePage from "./components/pages/homePage/homePage";
import ArticlesPage from "./components/pages/articlesPage/articlesPage";
import PolyciPage from "./components/pages/policyPage/policyPage";
import Head from "./components/head/head";
import Modal from "./components/modal/modal";
import PopularPage from "./components/pages/popular/popular";
import SelectedArticlePage from "./components/pages/selectedArticlePage/selectedArticle";
import "./components/scss/app.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  const [modal, setModal] = useState(false);

  const showModal = () => {
    setModal(!modal);
  };

  return (
    <BrowserRouter>
      <Head onClick={showModal} />
      {modal && <Modal onClick={showModal} />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/articles" element={<ArticlesPage />} />
        <Route path="/articles/article/:id" element={<SelectedArticlePage />} />
        <Route path="/privacy" element={<PolyciPage />} />
        <Route path="/popular" element={<PopularPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
